<template>
  <div>
    <KTCard ref="preview" v-bind:title="'Edit'">
      <template v-slot:title>
        <div class="card-title">
          <h3 class="card-label">
            {{ $t("CREDIT_DEBIT_ACTION_REVIEW") }}
          </h3>
        </div>
      </template>
      <template v-slot:toolbar>
        <div class="card-toolbar">
          <ModalAddToInvoice
            v-if="stockCreditDebitAction.invoiceId == null"
            :stockCreditDebitAction="stockCreditDebitAction"
            :type="creditDebitType()"
            :documentType="creditDebitDocumentType()"
            :source="'credit-debit'"
            @addedToInvoice="addedToInvoiceCreditDebit($event)"
          ></ModalAddToInvoice>
        </div>
      </template>
      <template v-slot:body>
        <b-row v-if="!firstLoader">
          <StockInfoPanels
            :forDate="stockCreditDebitAction.forDate"
            :userName="stockCreditDebitAction.userName"
          >
          </StockInfoPanels>
        </b-row>
        <b-row>
          <b-col :sm="12" v-if="!firstLoader">
            <StockCreditDebitActionItemsPreviewTable
              :headers="dynamicHeaders"
              :items="stockCreditDebitAction.items"
              :itemsTotal="stockCreditDebitAction.docTotal"
              :showTotalPrice="showTotalPrice"
            >
            </StockCreditDebitActionItemsPreviewTable>
          </b-col>
        </b-row>

        <b-row>
          <b-col :sm="6">
            <ReviewDocInfo
              :stockAction.sync="stockCreditDebitAction"
              :heading="stockCreditDebitAction.counterpartyName"
            />
          </b-col>
        </b-row>
        <b-form>
          <SkeletonLoaderMain :visible="firstLoader"></SkeletonLoaderMain>
          <v-overlay :value="!firstLoader && isLoadingStock == true">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </b-form>
      </template>
      <template v-slot:foot>
        <div
          v-show="_.size(errors) > 0"
          role="alert"
          class="alert alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
      </template>
      <template v-slot:toolbarfoot>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  FETCH_CREDIT_DEBIT_STOCK_ACTION,
  UPDATE_ECHO_AJUR
} from "@/modules/stock/store/stock.module";

import ModalAddToInvoice from "@/modules/stock/components/actions/stockList/ModalAddToInvoice.vue";

import StockInfoPanels from "@/modules/stock/components/StockInfoPanels.vue";

import StockCreditDebitActionItemsPreviewTable from "@/modules/stock/components/actions/StockCreditDebitActionItemsPreviewTable.vue";

import ReviewDocInfo from "@/modules/stock/components/actions/ReviewDocInfo.vue";

import KTCard from "@/view/content/Card.vue";
import { mapGetters, mapState } from "vuex";

export default {
  name: "StockCreditDebitActionReview",
  mixins: [],
  data() {
    return {
      errors: {},
      isXLSDownloadLoading: false,
      firstLoader: true,
      stockCreditDebitAction: {},
      showTotalPrice: false,
      startHeaders: [
        {
          text: this.$t("ITEM"),
          align: "start",
          filterable: true,
          value: "name"
        }
      ],
      endHeaders: []
    };
  },
  components: {
    KTCard,
    StockInfoPanels,
    StockCreditDebitActionItemsPreviewTable,
    ReviewDocInfo,
    ModalAddToInvoice
  },
  mounted() {
    let vm = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Stock action review", route: { name: "stock-action-review" } }
    ]);

    vm.getStockCreditDebitAction();
  },
  computed: {
    ...mapGetters(["isLoadingStock", "currentUser"]),
    ...mapState({
      errors: state => state.auth.errors
    }),
    dynamicHeaders() {
      let vm = this;
      let dynamicHeaders = vm.startHeaders;
      //Receiving
      let addHeaders = [
        {
          text: this.$t("STORE"),
          value: "storeName",
          sortable: true
        },
        {
          text: this.$t("RECEIVE"),
          value: "unitValue",
          sortable: true
        },
        {
          text: this.$t("SINGLE_PRICE"),
          value: "singlePriceCurrency",
          sortable: true
        },
        {
          text: this.$t("TOTAL_PRICE"),
          value: "totalPriceCurrency",
          sortable: true
        }
      ];
      dynamicHeaders = [...dynamicHeaders, ...addHeaders, ...vm.endHeaders];
      return dynamicHeaders;
    }
  },
  methods: {
    getStockCreditDebitAction() {
      let vm = this;
      let id = this.$route.params.id;
      this.$store
        .dispatch(FETCH_CREDIT_DEBIT_STOCK_ACTION, id)
        .then(data => {
          // vm.$notify({
          //   group: "notify",
          //   type: "success",
          //   title: "<i class='flaticon2-checkmark'></i> Success",
          //   text: data.message
          // });
          vm.firstLoader = false;
          vm.$nextTick(function() {
            vm.stockCreditDebitAction = data.data;
          });
        })
        .catch(response => {
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Nout Found"
            });
            this.$router.push({ name: "stock-actions-list" });
          }
        });
    },
    creditDebitType() {
      let vm = this;
      if (vm.stockCreditDebitAction.typeId == 1) {
        return "outgoing";
      } else if (vm.stockCreditDebitAction.typeId == 2) {
        return "incoming";
      }
    },
    creditDebitDocumentType() {
      let vm = this;
      if (vm.stockCreditDebitAction.documentTypeId == 3) {
        return "credit";
      } else if (vm.stockCreditDebitAction.documentTypeId == 4) {
        return "debit";
      }
    },
    addedToInvoiceCreditDebit(args) {
      let vm = this;
      let invoiceId = args.invoiceId;
      vm.$router.push({
        name: "stock-invoice-review",
        params: { id: invoiceId }
      });
      // this.doSearch();
    },
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map(key => key + "=" + params[key])
          .join("&");
      return apiParams;
    }
  }
};
</script>
