<template>
  <div>
    <v-card-title>
      <v-text-field
        v-model="search"
        clearable
        append-icon="mdi-magnify"
        :label="$t('SEARCH')"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      :footer-props="{
        showFirstLastPage: true,
        itemsPerPageOptions: rowsPerPageItems,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right'
      }"
    >
      <template v-slot:item.name="{ item }">
        <strong>{{ item.warehouseName }}</strong> | {{ item.name }}
        <small>({{ item.unitShortName }})</small>
      </template>
      <template v-slot:item.stockActionItem.packageValue="{ item }">
        <strong>{{ item.stockActionItem.packageValue }}</strong>
        <small> ({{ item.packageName }})</small>
      </template>
      <template v-slot:item.stockActionItem.unitValue="{ item }">
        <strong>{{ item.stockActionItem.unitValue }}</strong>
        <small> ({{ item.unitShortName }})</small>
      </template>
      <template v-slot:item.scrapping.stockScrappingReasonId="{ item }">
        {{
          findInSelect(item.scrapping.stockScrappingReasonId, scrappingReasons)
        }}
      </template>
      <template v-slot:item.scrapping.stockScrappingTypeId="{ item }">
        {{ findInSelect(item.scrapping.stockScrappingTypeId, scrappingTypes) }}
      </template>
      <template v-slot:item.testing.stockTestingReasonId="{ item }">
        {{ findInSelect(item.testing.stockTestingReasonId, testingReasons) }}
      </template>
      <template v-if="showTotalPrice" slot="body.append">
        <tr class="previewTr">
          <th colspan="100%">
            {{ $t("TOTAL_PRICE") }}:
            {{ itemsTotal }}
          </th>
        </tr>
      </template>
      <template v-if="showTotalPriceWholesale" slot="body.append">
        <tr class="previewTr">
          <th colspan="100%">
            {{ $t("TOTAL_PRICE_WHOLESALE") }}:
            {{ itemsTotalWholesale }}
          </th>
        </tr>
      </template>

      <template v-slot:item.stockWholesaleItem.singlePrice="{ item }">
        <v-text-field
          type="text"
          placholder="123"
          hide-details
          v-model="item.stockWholesaleItem.singlePrice"
          @blur="filterSinglePrice(item)"
        ></v-text-field>
      </template>
      <template v-slot:item.stockWholesaleItem.totalPrice="{ item }">
        <v-text-field
          type="text"
          placholder="123"
          hide-details
          v-model="item.stockWholesaleItem.totalPrice"
          @blur="filterTotalPrice(item)"
        ></v-text-field>
      </template>

      <template v-slot:item.stockWholesaleItem.singlePriceCurrency="{ item }">
        <v-text-field
          type="text"
          placholder="123"
          hide-details
          v-model="item.stockWholesaleItem.singlePriceCurrency"
          @blur="filterSinglePriceWholesale(item)"
        ></v-text-field>
      </template>
      <template v-slot:item.stockWholesaleItem.totalPriceCurrency="{ item }">
        <v-text-field
          type="text"
          placholder="123"
          hide-details
          v-model="item.stockWholesaleItem.totalPriceCurrency"
          @blur="filterTotalPriceWholesale(item)"
        ></v-text-field>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn
          class="mx-2"
          fab
          dark
          x-small
          color="#1BC5BD"
          @click="updateWholesaleItem(item)"
        >
          <v-icon dark>mdi-content-save</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>
<style scoped>
tr.previewTr th {
  font-size: 18px !important;
}
</style>
<script>
import _ from "lodash";
export default {
  name: "StockCreditDebitActionItemsPreviewTable",
  inheritAttrs: false,
  props: {
    headers: {
      type: Array,
      default: function() {
        return [];
      }
    },
    items: {
      type: Array,
      default: function() {
        return [];
      }
    },
    itemsTotal: {
      type: Array,
      default: function() {
        return [];
      }
    },
    itemsTotalWholesale: {
      type: Array,
      default: function() {
        return [];
      }
    },
    showTotalPrice: {
      type: Boolean,
      default: true
    },
    showTotalPriceWholesale: {
      type: Boolean,
      default: false
    },
    scrappingReasons: {
      type: Array,
      default: function() {
        return [
          {
            id: 1,
            name: "Изтекъл срок на годност"
          }
        ];
      }
    },
    scrappingTypes: {
      type: Array,
      default: function() {
        return [];
      }
    },
    testingReasons: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data() {
    return {
      search: "",
      rowsPerPageItems: [-1, 100, 10]
    };
  },
  components: {},
  beforeMount() {},
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    updateWholesaleItem(item) {
      this.$emit("updateWholesaleItem", item);
    },
    findInSelect(id, options) {
      let item = _.find(options, { id: id });
      if (item) {
        return item.name;
      } else {
        return "-";
      }
    },

    filterSinglePrice(item) {
      item.stockWholesaleItem.singlePrice = this.$options.filters.formatBalance(
        item.stockWholesaleItem.singlePrice
      );
      this.updateItemTotalPrice(item);
    },
    filterTotalPrice(item) {
      item.stockWholesaleItem.totalPrice = this.$options.filters.formatBalance(
        item.stockWholesaleItem.totalPrice
      );
      this.updateItemSinglePrice(item);
    },
    updateItemTotalPrice(item) {
      let value =
        parseFloat(item.stockActionItem.unitValue) *
        parseFloat(item.stockWholesaleItem.singlePrice);
      item.stockWholesaleItem.totalPrice = this.$options.filters.formatBalance(
        value
      );
    },
    updateItemSinglePrice(item) {
      let value =
        parseFloat(item.stockWholesaleItem.totalPrice) /
        parseFloat(item.stockActionItem.unitValue);
      item.stockWholesaleItem.singlePrice = this.$options.filters.formatBalance(
        value
      );
    },

    filterSinglePriceWholesale(item) {
      item.stockWholesaleItem.singlePriceCurrency = this.$options.filters.formatBalance(
        item.stockWholesaleItem.singlePriceCurrency
      );
      this.updateItemTotalPriceWholesale(item);
    },
    filterTotalPriceWholesale(item) {
      item.stockWholesaleItem.totalPriceCurrency = this.$options.filters.formatBalance(
        item.stockWholesaleItem.totalPriceCurrency
      );
      this.updateItemSinglePriceWholesale(item);
    },
    updateItemTotalPriceWholesale(item) {
      let value =
        parseFloat(item.stockActionItem.unitValue) *
        parseFloat(item.stockWholesaleItem.singlePriceCurrency);
      item.stockWholesaleItem.totalPriceCurrency = this.$options.filters.formatBalance(
        value
      );
    },
    updateItemSinglePriceWholesale(item) {
      let value =
        parseFloat(item.stockWholesaleItem.totalPriceCurrency) /
        parseFloat(item.stockActionItem.unitValue);
      item.stockWholesaleItem.singlePriceCurrency = this.$options.filters.formatBalance(
        value
      );
    }
  }
};
</script>
